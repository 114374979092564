@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

body {
  background: linear-gradient(to bottom, #181d35, #13ac9b);
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}

.underline {
  text-decoration: underline;
}

.logo {
  margin-bottom: 50px;
}

.bg-lightGray {
  margin-top: 20px;
  background-color: #e6e6e6;
}

.bg-primary {
  background-color: #13ac9b !important;
}

.bg-secondary {
  background-color: #e6e6e6 !important;
}

.bgGray {
  background: #808080;
}

.text-light {
  opacity: 0.5;
}

.text-primary {
  color: #13ac9b !important;
}

.text-darklight {
  color: #181d35;
  opacity: 0.7;
}

.text-darklightgray {
  color: #181d35;
  opacity: 0.5;
}

.fs-15 {
  font-size: 1.5rem;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.br-12 {
  border-radius: 12px;
}

.b-1 {
  border: 1px solid #3a3a3a !important;
}

.b-2 {
  border: 2px solid #181d35;
}

.br-30 {
  border-radius: 30px;
  margin-left: 2px;
  margin-right: 2px;
}

.fs-14 {
  font-size: 14px;
}

.fs-40 {
  font-size: 40px !important;
}

.p-r {
  position: relative;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.mtminus-5 {
  margin-top: -5px;
}

.mtminus-10 {
  margin-top: -10px;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.fa-circle {
  font-size: 8px !important;
}

.fa-circle-xmark {
  background-color: #fff !important;
}

.borderfull {
  border: 1px solid #888;
  border-radius: 8px;
}

.b-dashed {
  border: 2px dashed #888;
}

.bg-transparent {
  background: transparent !important;
}

.bg-white {
  background-color: #fff !important;
}

.w-fitContent {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.minWidth-45 {
  min-width: 45% !important;
}

.minWidth-47 {
  min-width: 47% !important;
}

.minWidth-48 {
  min-width: 48% !important;
}

.minWidth-49 {
  min-width: 49% !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.col-6 {
  padding: 0 5px;
}

.form-control:focus {
  box-shadow: none;
}

.box-shadow {
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}

.serif {
  font-family: serif !important;
}

button {
  font-family: "Montserrat", sans-serif;
}

/* Container für die Zentrierung */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button-Stil */

.red-button {
  color: white;
  font-weight: bolder;
  font-size: larger;
  width: 280px;
  height: 56px;
  border-radius: 16px;
  background-color: #eb3b39;
  box-shadow: 0px 4px 0px 0px #454545 !important; /* Schatten standardmäßig nach unten */
  padding: 10px 0;
  margin: 20px 0;
  transition: box-shadow 0.2s; /* Hinzugefügte Übergangsanimation */
}

.red-button:active {
  box-shadow: 0px -4px 0px 0px #454545 !important; /* Schatten nach oben, wenn geklickt */
}

.blue-button {
  background-color: #181d35;
  height: 50px;
  border: 1px solid #7f818d;
  box-shadow: 0 3px 0 #7f818d;
  color: #fff;
  padding: 0 20px; /* 10px links und rechts */
  border-radius: 8px; /* Runde Ecken */
  transition: box-shadow 0.2s, width 0.2s; /* Animation für den Schatten und die Breite */
  text-align: center; /* Zentriert den Text horizontal */
}

/* Stil beim Klicken */
.blue-button:active {
  box-shadow: none; /* Schatten entfernen */
  padding: 0 10px;
}

/* CSS für das Label */
.label-white {
  background-color: white; /* Hintergrundfarbe weiß */
  width: 100%; /* 80% der Bildschirmbreite */
  border-radius: 20px; /* Ecken abgerundet mit 8px */
  padding: 10px; /* 10px Innenabstand rundherum */
  display: flex;
  flex-direction: column;
  margin: 6px 0; /* 5px oben und unten Abstand */
}

.selected-item {
  background-color: rgba(200, 200, 200, 0.7);
  border-radius: 8px;
  color: black;
  display: inline-block;
  padding: 5px 8px;
  margin: 5px;
}

.selected-item.blue {
  background-color: #181d35;
  color: white;
}

.tab-buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 20px; /* Hier fügen wir links und rechts einen Abstand von 10 Pixeln hinzu */
}

.tab-button {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tab-button.active {
  background-color: #2e2e2e;
  color: white;
}

.keyword-button {
  background-color: #d1d1d1;
  border: 1px solid #000000;
  border-radius: 16px;
  padding: 8px;
  margin: 5px;
}

.keyword-button-selected {
  background-color: #bcddff;

  border-radius: 16px;
  padding: 8px;
  margin: 5px;
}

.keyword-container {
  display: inline-block;
  margin-right: 10px;
}

.remove-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  margin-left: 5px; /* Fügt einen Abstand zwischen dem Label und dem "x" hinzu */
}

/* CSS für die Bezeichnung */
.label-white-title {
  font-size: 10px; /* Bezeichnung 5px hoch */
}

.exercise-img {
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.exercise-vid {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 16px !important;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  border: none;
}

.btn-close {
  background: none;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding: 0 !important;
  opacity: 1 !important;
  color: #13ac9b !important;
}

.fa-xmark {
  color: #13ac9b !important;
  font-size: 25px;
  padding-top: 5px;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #181d35;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif !important;
}

.trainingChat {
  font-size: 10px;
  font-weight: bold;
}

.chat-container {
  position: relative;
}

.chat-sticky-header {
  position: sticky;
  top: 50px;
  height: 50px;
  background-color: #f0f0f0;
  padding: 10px;
  z-index: 999;
}

.inline-h1 {
  margin-top: 20px;
  display: inline;
}

.inline-h2 {
  font-size: 32px;
  font-weight: bold;
  display: inline-block;
  margin-left: 8px;
}

ul li {
  list-style-type: circle;
  /* Bullets in Form von gefüllten Kreisen */
  /* Weitere mögliche Werte:
    disc: gefüllte Kreise
    circle: leere Kreise
    square: gefüllte Quadrate
    none: keine Bullets
  */
  /*
  margin-left: 1.5em; <-- Abstand zum Rand
  
  */
}

main {
  width: 100vw;
}

.loginForm {
  text-align: center;
  height: 100vh;
  /*background: url(../images/loginbg.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.loginForm .loginBtn {
  width: 280px;
  height: 56px;
  border-radius: 16px;
  background-color: #eb3b39;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.loginForm .loginBtn a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginForm .btn.active,
.loginForm .btn.show,
.loginForm .btn:first-child:active {
  background-color: #eb3b39;
}
.loginForm button:focus:not(:focus-visible) {
  background-color: #eb3b39;
  outline: none;
  border: none;
}

.categoryTitle {
  margin-top: 30px;
}

.MenuLink {
  font-weight: 500 !important;
  margin-bottom: 20px;
  list-style: none;
}

.toggleMenu {
  display: none;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.toggle_MenuBtn {
  cursor: pointer;
}

.ToggleDown {
  display: block !important;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.toggle_MenuBtn.active .toggleMenu {
  display: block !important;
}

.customMenuIconPlus {
  width: 55px !important;
  height: 55px !important;
}

.customMenuBottomOff {
  width: 100vw;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.customMenuBottomOff .customMenuIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #13ac9b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  cursor: pointer;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.customMenuBottomOff .customMenuIcon img {
  width: 20px;
  height: 20px;
}
.customMenuBottomOff .customMenuIcon .PlusBtn {
  width: 100%;
  height: 100%;
}

.offcanvas {
  z-index: 99999;
}

.offcanvas-left {
  /*background: url(../../images/user-menu-bg.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100vw !important;
  left: 0 !important;
}
.offcanvas-left .header {
  background: transparent !important;
}

.customMenuBottom {
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
}
.customMenuBottom .customMenuIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  background-color: #13ac9b;
  cursor: pointer;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.customMenuBottom .customMenuIcon .PlusBtn {
  font-size: 40px;
  opacity: 0.7;
  color: #13ac9b;
}
.customMenuBottom .offcanvas.offcanvas-bottom {
  /*background: url(./../images/user-menu-bg.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.customMenuBottom .header {
  background-color: transparent !important;
}
.customMenuBottom .header .userProfile,
.customMenuBottom .header .userProfile2,
.customMenuBottom .header .userProfile3 {
  position: relative;
}
.customMenuBottom .header .userProfile img,
.customMenuBottom .header .userProfile2 img,
.customMenuBottom .header .userProfile3 img {
  width: 40px !important;
  height: 40px !important;
}
.customMenuBottom .offcanvas-backdrop.show {
  opacity: 0 !important;
}

.dot {
  font-size: 40px;
  position: absolute;
  top: 2%;
  right: 10%;
}

.dot2 {
  font-size: 40px;
  position: absolute;
  top: 0%;
  left: 40%;
}

/* wird verwendet um den Namen Fett zu machen, wenn ungelesene Chats vorhanden sind */
.bold-name {
  font-weight: bold;
}

.wrapper {
  min-height: 100vh;
  /*background: url(../images/loginbg.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.header .flag,
.header .flag2,
.header .flag3 {
  margin-right: 15px;
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
  position: relative;
}
.header .flag img,
.header .flag2 img,
.header .flag3 img {
  width: 40px !important;
  height: 40px !important;
}
.header .compressView.expand {
  width: 100% !important;
}
.header .compressView2.expand2 {
  width: 100% !important;
}
.header .compressView3.expand3 {
  width: 100% !important;
}
.header .compressView,
.header .compressView2,
.header .compressView3 {
  width: 40px;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 40px;
  border-radius: 30px;
  border: 1px solid #888;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.header .compressView input,
.header .compressView2 input,
.header .compressView3 input {
  border: none;
  background-color: transparent;
  outline: none;
}
.header .compressView input:focus:not(:focus-visible),
.header .compressView2 input:focus:not(:focus-visible),
.header .compressView3 input:focus:not(:focus-visible) {
  outline: none;
  border: none;
}
.header .compressView .SearchArrow,
.header .compressView2 .SearchArrow,
.header .compressView3 .SearchArrow {
  background-color: #13ac9b;
  width: 20%;
  height: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header .userProfile,
.header .userProfile2,
.header .userProfile3 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #888;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card .RArrow {
  width: 50px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #181d35;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.card .RArrow i {
  color: #181d35;
}

.toggle_btn {
  cursor: pointer;
}

.toggle_btn.active .fas {
  transform: rotate(180deg);
}

.toggle_text2 {
  display: none;
}

.toggle_btn.active .toggle_text2 {
  display: flex !important;
}

.toggle_btn.active .toggle_text {
  display: none !important;
}

.textToHide {
  display: none;
  font-size: 14px !important;
}
.textToHide span {
  font-size: 14px !important;
}

.textToHideWrapper.active .textToHide2 {
  display: none !important;
}

.textToHideWrapper.active .textToHide {
  display: flex !important;
}

.toggleDrop {
  display: none;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.toggleDrop hr {
  border: 1px dashed #181d35;
}
.toggleDrop .card {
  position: relative;
}
.plusIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.toggleDrop.active {
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: auto;
  overflow: auto;
  transition: all 0.5s ease-in-out;
}

.morganBtnWrap .morganBtn {
  width: 60%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #181d35 !important;
}
.morganBtnWrap .morganBtn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schulerCard {
  height: 60px;
  margin-bottom: 5px;
}
.schulerCard .bagdeIcon {
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-left {
  margin-left: 10px;
}

.btn {
  font-size: 10px;
}

.UbungenImage {
  width: 100%;
  height: 100px;
  border-radius: 12px;
  border: 1px solid #eee;
}

.AnhangBlock {
  border: 1px dashed #888;
  background-color: #e6e6e6;
  position: relative;
  height: 100px;
  width: 100%;
}

.AndersonImage img {
  border-radius: 40px;
}

.ListImage img {
  border-radius: 25px;
}

.space-bottom {
  margin-bottom: 10px;
}

.NoteInput {
  width: 100%;
  padding: 10px;
}

.NewMessageChat {
  position: fixed;
  bottom: 100px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.NewMessageChat input {
  flex: 1;
}

/* .NewMessageChat button {
} */

.toast-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.plusCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}

.plusCircle-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: absolute;
  top: 10px;
  right: 20px;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}

.progress {
  height: 8px;
  width: 95%;
}
.progress .progress-bar {
  background-color: #13ac9b;
}

.headerImageCard {
  width: 100%;
  height: 80px;
}
.headerImageCard img {
  width: 100%;
  height: 100%;
}

.vertDivider {
  display: flex;
  width: 1px;
  height: 20px;
  background-color: #fff;
}

.minusCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}

.SubMenuWrapper {
  /*background: url(./../images/user-menu-bg.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}
.SubMenuWrapper .header {
  background: transparent !important;
}
.SubMenuWrapper .ubungDiv {
  display: none;
}
.SubMenuWrapper .ubungBtn {
  width: 100%;
  height: 48px;
  margin-bottom: 15px;
  display: flex;
  box-shadow: 0px 4px 0px 0px #888 !important;
  flex-direction: column;
}
.SubMenuWrapper .ErstellenBtn {
  width: 40%;
  height: 56px;
  border-radius: 12px;
  background-color: #181d35;
  margin: 30px 30%;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.SubMenuWrapper .hausaufgabeDiv {
  display: none;
}
.SubMenuWrapper .hausaufgabeBtn {
  padding: 5px 2px;
  width: 100%;
  min-height: 48px;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 0px 0px #888 !important;
}
.SubMenuWrapper .h-100 {
  min-height: 100px !important;
}
.SubMenuWrapper .nachrechtenBtn {
  padding: 5px 2px;
  width: 100%;
  border: 1px solid #181d35;
  height: 56px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}

.bagdeIcon {
  width: 20px;
  height: 25px;
  cursor: pointer;
  position: relative;
}
.bagdeIcon .toggleImg1,
.bagdeIcon .toggleImg2,
.bagdeIcon .toggleImg3 {
  position: absolute;
  top: 0;
}
.bagdeIcon .toggleImg2,
.bagdeIcon .toggleImg3 {
  display: none;
}

.toggleImgBtn {
  width: 20px;
  height: 25px;
  cursor: pointer;
  position: relative;
}
.toggleImgBtn .toggleImg1,
.toggleImgBtn .toggleImg2,
.toggleImgBtn .toggleImg3 {
  position: absolute;
  top: 0;
}
.toggleImgBtn .toggleImg2,
.toggleImgBtn .toggleImg3 {
  display: none;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: #181d35 !important;
  font-weight: 500;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background-color: #181d35 !important;
  border-color: #181d35 !important;
}

.mbsc-ios.mbsc-datepicker-inline {
  border: none !important;
} /*# sourceMappingURL=style.css.map */
