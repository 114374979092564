@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
p {
  margin-bottom: 0;
}
// h1 {
//   font-size: 2.2em !important;
// }
.underline {
  text-decoration: underline;
}
.bg-lightGray {
  background-color: #e6e6e6;
}
.bg-primary {
  background-color: #13ac9b !important;
}
.bg-secondary {
  background-color: #e6e6e6 !important;
}
.bgGray {
  background: #808080;
}
.text-light {
  opacity: 0.5;
}
.text-primary {
  color: #13ac9b !important;
}
.text-darklight {
  color: #181d35;
  opacity: 0.7;
}
.text-darklightgray {
  color: #181d35;
  opacity: 0.5;
}
.fs-15 {
  font-size: 1.5rem;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.br-12 {
  border-radius: 12px;
}
.b-1 {
  border: 1px solid #181d35 !important;
}
.b-2 {
  border: 2px solid #181d35;
}
.br-30 {
  border-radius: 30px;
}
.fs-14 {
  font-size: 14px;
}
.fs-40 {
  font-size: 40px !important;
}
.p-r {
  position: relative;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}
.mtminus-5 {
  margin-top: -5px;
}
.mtminus-10 {
  margin-top: -10px;
}
.w-fit {
  width: fit-content;
}
.fa-circle {
  font-size: 8px !important;
}
.fa-circle-xmark {
  background-color: #fff !important;
}
.borderfull {
  border: 1px solid #888;
  border-radius: 8px;
}
.b-dashed {
  border: 2px dashed #888;
}
.bg-transparent {
  background: transparent !important;
}
.bg-white {
  background-color: #fff !important;
}
.w-fitContent {
  width: fit-content !important;
}
.minWidth-45 {
  min-width: 45% !important;
}
.minWidth-47 {
  min-width: 47% !important;
}
.minWidth-48 {
  min-width: 48% !important;
}
.minWidth-49 {
  min-width: 49% !important;
}
.pt-150 {
  padding-top: 150px !important;
}
.col-6 {
  padding: 0 5px;
}
.form-control:focus {
  box-shadow: none;
}
.box-shadow {
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.serif {
  font-family: serif !important;
}
button {
  font-family: "Montserrat", sans-serif;
}
.btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 16px !important;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  border: none;
}
.btn-close {
  background: none;
  width: fit-content !important;
  height: fit-content !important;
  padding: 0 !important;
  opacity: 1 !important;
  color: #13ac9b !important;
}

.fa-xmark {
  color: #13ac9b !important;
  font-size: 25px;
  padding-top: 5px;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #181d35;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: "Abhaya Libre", serif !important;
  font-family: sans-serif !important;
}
ul li {
  list-style: none;
}

main {
  width: 100vw;
}
.loginForm {
  text-align: center;
  height: 100vh;
  //background: url(../images/loginbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .loginBtn {
    width: 280px;
    height: 56px;
    border-radius: 16px;
    background-color: #eb3b39;
    box-shadow: 0px 4px 0px 0px #181d35 !important;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn.active,
  .btn.show,
  .btn:first-child:active {
    background-color: #eb3b39;
  }
  button:focus:not(:focus-visible) {
    background-color: #eb3b39;
    outline: none;
    border: none;
  }
}

.MenuLink {
  font-weight: 500 !important;
  margin-bottom: 20px;
}
.toggleMenu {
  display: none;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.toggle_MenuBtn {
  cursor: pointer;
}
.ToggleDown {
  display: block !important;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.toggle_MenuBtn.active .toggleMenu {
  display: block !important;
}
.customMenuIconPlus {
  width: 55px !important;
  height: 55px !important;
}
.customMenuBottomOff {
  width: 100vw;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  padding-left: 20px;
  // background-color: #fff !important;
  & .customMenuIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #13ac9b;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    cursor: pointer;
    box-shadow: 0px 4px 0px 0px #181d35 !important;

    & img {
      width: 20px;
      height: 20px;
    }
    .PlusBtn {
      width: 100%;
      height: 100%;
    }
  }
}
.offcanvas {
  z-index: 99999;
}
.offcanvas-left {
  background: url(../../images/user-menü-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100vw !important;
  left: 0 !important;

  .header {
    background: transparent !important;
  }
}

.customMenuBottom {
  width: 100vw;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(19, 172, 155, 0.8)
  );
  padding-left: 20px;
  // box-shadow: 2px 2px 5px 10px #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  .customMenuIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    background-color: #13ac9b;
    cursor: pointer;
    box-shadow: 0px 4px 0px 0px #181d35 !important;

    // & img {
    //   width: 20px;
    //   height: 20px;
    // }
    .PlusBtn {
      font-size: 40px;
      opacity: 0.7;
      color: #13ac9b;
    }
  }
  .offcanvas.offcanvas-bottom {
    background: url(../../images/user-menü-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
  .header {
    background-color: transparent !important;

    .userProfile,
    .userProfile2,
    .userProfile3 {
      position: relative;
      img {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }

  .offcanvas-backdrop.show {
    opacity: 0 !important;
  }
}
.dot {
  font-size: 40px;
  position: absolute;
  top: 2%;
  right: 10%;
}
.dot2 {
  font-size: 40px;
  position: absolute;
  top: 0%;
  left: 40%;
}
.ubungenCard {
  width: 360px;
}
// .ubungenCard.expandCard {
//   height: fit-content !important;
// }
.wrapper {
  min-height: 100vh;
  //background: url(../images/loginbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;

  .flag,
  .flag2,
  .flag3 {
    margin-right: 15px;
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    position: relative;
    & img {
      width: 40px !important;
      height: 40px !important;
    }
  }
  .compressView.expand {
    width: 100% !important;
  }
  .compressView2.expand2 {
    width: 100% !important;
  }
  .compressView3.expand3 {
    width: 100% !important;
  }
  .compressView,
  .compressView2,
  .compressView3 {
    width: 40px;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 40px;
    border-radius: 30px;
    border: 1px solid #888;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 0px 0px #181d35 !important;

    & input {
      border: none;
      background-color: transparent;
      outline: none;
    }
    input:focus:not(:focus-visible) {
      outline: none;
      border: none;
    }
    .SearchArrow {
      background-color: #13ac9b;
      width: 20%;
      height: 100%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .userProfile,
  .userProfile2,
  .userProfile3 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #888;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.card {
  .RArrow {
    width: 50px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #181d35;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 0px 0px #181d35 !important;

    & i {
      color: #181d35;
    }
  }
}
.toggle_btn {
  cursor: pointer;
}
.toggle_btn.active .fas {
  transform: rotate(180deg);
}
.toggle_text2 {
  display: none;
}
.toggle_btn.active .toggle_text2 {
  display: flex !important;
}
.toggle_btn.active .toggle_text {
  display: none !important;
}
.textToHide {
  display: none;
  font-size: 14px !important;
  span {
    font-size: 14px !important;
  }
}

.textToHideWrapper.active .textToHide2 {
  display: none !important;
}
.textToHideWrapper.active .textToHide {
  display: flex !important;
}
.toggleDrop {
  // height: 0;
  display: none;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

  & hr {
    border: 1px dashed #181d35;
  }

  & .card {
    position: relative;
  }
  .plusIcon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}

.toggleDrop.active {
  min-height: fit-content;
  height: auto;
  overflow: auto;
  transition: all 0.5s ease-in-out;
}
.morganBtnWrap {
  .morganBtn {
    width: 60%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #181d35 !important;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.schulerCard {
  height: 60px;
  margin-bottom: 5px;

  & .bagdeIcon {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn {
  font-size: 10px;
}
.UbungenImage {
  width: 100%;
  height: 100px;
  border-radius: 12px;
  border: 1px solid #eee;
}

.AnhangBlock {
  border: 1px dashed #888;
  background-color: #e6e6e6;
  position: relative;
  height: 100px;
  width: 100%;
}
.plusCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.progress {
  height: 8px;
  width: 95%;

  .progress-bar {
    background-color: #13ac9b;
  }
}
.headerImageCard {
  width: 100%;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
  }
}
.vertDivider {
  display: flex;
  width: 1px;
  height: 20px;

  background-color: #fff;
}
.minusCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;

  box-shadow: 0px 4px 0px 0px #181d35 !important;
}
.SubMenuWrapper {
  background: url(../../images/user-menü-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  width: 100%;

  .header {
    background: transparent !important;
  }
  .ubungDiv {
    display: none;
  }
  .ubungBtn {
    width: 100%;
    height: 48px;
    margin-bottom: 15px;
    display: flex;
    box-shadow: 0px 4px 0px 0px #888 !important;
    flex-direction: column;
  }
  .ErstellenBtn {
    width: 40%;
    height: 56px;
    border-radius: 12px;
    background-color: #181d35;
    margin: 30px 30%;
    box-shadow: 0px 4px 0px 0px #181d35 !important;
  }
  .hausaufgabeDiv {
    display: none;
  }
  .hausaufgabeBtn {
    padding: 5px 2px;
    width: 100%;
    min-height: 48px;
    height: fit-content;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 0px 0px #888 !important;
  }
  .h-100 {
    min-height: 100px !important;
  }
  .nachrechtenBtn {
    padding: 5px 2px;
    width: 100%;
    border: 1px solid #181d35;
    height: 56px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 0px 0px #181d35 !important;
  }
}
.bagdeIcon {
  width: 20px;
  height: 25px;
  cursor: pointer;
  position: relative;

  .toggleImg1,
  .toggleImg2,
  .toggleImg3 {
    position: absolute;
    top: 0;
  }

  .toggleImg2,
  .toggleImg3 {
    display: none;
  }
}
.toggleImgBtn {
  width: 20px;
  height: 25px;
  cursor: pointer;
  position: relative;

  .toggleImg1,
  .toggleImg2,
  .toggleImg3 {
    position: absolute;
    top: 0;
  }

  .toggleImg2,
  .toggleImg3 {
    display: none;
  }
}
.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: #181d35 !important;
  font-weight: 500;
}
.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background-color: #181d35 !important;
  border-color: #181d35 !important;
}
.mbsc-ios.mbsc-datepicker-inline {
  border: none !important;
}
